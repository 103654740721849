import React, { useState, useEffect, useContext, useRef } from 'react';
import Menu from '../../Menu';
import ModalConfirmacion from '../Confirmacion';
import AppContext from '../../../application/appContext';
import EncabezadoForm from '../Encabezado';
import { Typeahead } from 'react-bootstrap-typeahead';
const $ = window.$;

const FormServicio = ({ match, history }) => {
    const { confirmado, resetConfirmacion, mostrarAlerta, tokenValido, hacerlogout } = useContext(AppContext);
    const [ opcionesCatalogo, guardaOpcionesCatalogos ] = useState([]);

    const [ formMov, guardarForm ] = useState({
      producto: null,
      importe: '',
      referencia: '',
      observaciones: '',
      generarCfdi: false
    });

    const [ formError, stateErrorForm ] = useState( {
      producto: {
        required: false,
      },
      importe: {
        required: false,
        min: false,
      }
    });

    const refTypehead = useRef();

    useEffect(() => {
      if(confirmado) {
        $("#confirmacion").modal('hide');
        const request = {
          DocId: +match.params.idMov,
          IdServicio: formMov.producto[0].docId,
	        importe: +formMov.importe,
          Facturar: formMov.generarCfdi ? 1:0,
          Referencia: formMov.referencia,
          Observaciones: formMov.observaciones
        }

        // Validamos el token
        if( !tokenValido() ) {
          hacerlogout();
          return;
        }
        fetch(`${process.env.REACT_APP_API_URL}/Movimientos/CapturaServicios`, {
          method: 'POST',
          body: JSON.stringify( request ),
          headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        })
        .then(
          (result) => result.json()
        )
        .then(
          (result) => {

            if ( result.status >= 0) {
              mostrarAlerta( result.msg, 'success' );
              $('.toast').toast('show');
              resetConfirmacion();
              history.push(`/mov/srv`);
            }
            
            mostrarAlerta( result.msg, 'error' );
            $('.toast').toast('show');
            resetConfirmacion();
            
          }
        );
      }

      //Buscamos las opcines del catalogo
      fetch(`${process.env.REACT_APP_API_URL}/consultas/cat_srv`, {
          method: 'GET',
          headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        })
        .then(
          (result) => result.json()
        )
        .then(
          (result) => {
            guardaOpcionesCatalogos(result);
          }
        );
    }, [ confirmado ]);

    const onChange = ( e ) => {
      guardarForm({
        ...formMov,
        [e.target.name]: e.target.value
      });
    }

    const onChangeCheckbox = ( e ) => {
      guardarForm({
        ...formMov,
        [e.target.name]: !formMov.generarCfdi
      });
    }

    const onSubmit = (e) => {
      e.preventDefault();
      if ( !formMov.producto ) {
        stateErrorForm({
          ...formError,
          producto: {
            required: true,
          }
        });
        return;
      }
      if( formMov.importe.trim() === '' ) {
        stateErrorForm({
          ...formError,
          importe: {
            required: true,
            min: false
          }
        });
        return;
      }

      if( formMov.importe.trim() <= 0 ) {
        stateErrorForm({
          ...formError,
          importe: {
            required: false,
            min: true
          }
        });
        return;
      }

      stateErrorForm({
        producto: {
          required: false
        },
        importe: {
          required: false,
          min: false
        }
      });

      $("#confirmacion").modal('show');
    }

    const typeheadOnBlur = ( event ) => {
      if( !formMov.producto || formMov.producto.length <= 0 || event.target.value !== formMov.producto[0].descripcion ) {
        refTypehead.current.clear();
        guardarForm({
          ...formMov,
          producto: null
        });
      }
    }

    return ( 
      <div className="container pt-5">
        <Menu />
        <EncabezadoForm idMov={match.params.idMov} metodo="srv"/>
        <div className="row">
          <div className="col-12">
            <form className="form justify-content-center" autoComplete="off"
                onSubmit={onSubmit} noValidate>
              <div className="row">
                <div className="col-12 col-md-4">
                  <label className="mt-2" htmlFor="typehead">Nombre del producto, Gasto: </label>
                  <Typeahead
                    emptyLabel="No se encontraron resultados"
                    id="basic-typeahead-example"
                    isInvalid={formError.producto.required}
                    labelKey="descripcion"
                    onChange={(selected) => {
                      guardarForm({
                        ...formMov,
                        producto: selected
                      });
                    }}
                    onBlur={ typeheadOnBlur } 
                    options={ opcionesCatalogo }
                    ref={refTypehead}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label className="mt-2" htmlFor="inlineFormInputName2">Importe: </label>
                  <input type="number" id="inlineFormInputName2" placeholder=""
                    className={ formError.importe.required || formError.importe.min ? 'form-control is-invalid':'form-control' }
                    min="0"
                    name="importe"
                    onChange={onChange} 
                  />
                  { 
                    formError.required ? 
                      (<div className="invalid-feedback text-left">
                        El importe es requerido.
                      </div>) : null
                  }
                  {
                    formError.min ? 
                      (<div className="invalid-feedback text-left">
                        El importe debe ser mayor a 0.
                      </div>) : null
                  }
                </div>
                <div className="col-12 col-md-4">
                  <label className="mt-2" htmlFor="inlineFormInputName2">Referencia: </label>
                  <input type="text" className="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder=""
                    name="referencia"
                    onChange={onChange} 
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label className="mt-2" htmlFor="inlineFormInputName2">Observaciones: </label>
                  <textarea className="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder=""
                    name="observaciones"
                    onChange={onChange}
                  ></textarea>
                </div>
                <div className="col-12 col-md-4">
                  {/* Generar CFDI */}
                  <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" name="generarCfdi" onChange={onChangeCheckbox}/>
                    <label className="form-check-label" htmlFor="exampleCheck1">Generar CFDI</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 offset-md-4 text-center">
                  <button type="submit" className="btn btn-primary mb-2">Aplicar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ModalConfirmacion />
      </div>
    );
}
 
export default FormServicio;