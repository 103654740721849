import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import Home from './components/Home';

import AppState from './application/appState';
import RutaPrivada from './components/RutaPrivada';
import CuentasCobrar from './components/movimientos/CuentasCobrar';
import Movimiento from './components/movimientos/Movimiento';
import Alertas from './components/Alertas';
import CapturaServicio from './components/movimientos/srv/CapturaServicios';
import FormServicio from './components/movimientos/srv/FormServicios';
import RegistroGastos from './components/movimientos/gastos/RegistroGastos';
import FormGastos from './components/movimientos/gastos/FormGastos';
import FormPrefacturacion from './components/movimientos/prefacturacion/FormPrefacturacion';
import Reprocesar from './components/movimientos/reprocesar/Reprocesar';
import FormReprocesar from './components/movimientos/reprocesar/FormReprocesar';

function App() {
  return (
    <AppState>
      <Alertas />
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <RutaPrivada exact path="/home" component={Home} />
          <RutaPrivada exact path="/mov/srv" component={CapturaServicio} />
          <RutaPrivada exact path="/mov/srv/:idMov" component={FormServicio} />
          <RutaPrivada exact path="/mov/gastos" component={RegistroGastos} />
          <RutaPrivada exact path="/mov/gastos/:idMov" component={FormGastos} />
          <RutaPrivada exact path="/mov/refact" component={FormPrefacturacion} />
          <RutaPrivada exact path="/mov/rep" component={Reprocesar} />
          <RutaPrivada exact path="/mov/rep/info" component={FormReprocesar} />
          <RutaPrivada exact path="/mov/:metodo" component={CuentasCobrar} />
          <RutaPrivada exact path="/mov/:metodo/:idMov" component={Movimiento} />
        </Switch>
      </Router>
    </AppState>
  );
}

export default App;
