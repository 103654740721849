import { ALERTAS_MSG,
    MOSTRAR_LOGIN_ERRORES, MOSTRAR_LOGIN_ERROR_AUTH,
    LOGIN_EXISTOSO, LOGOUT_EXISTOSO,
    MOVIMIENTO_CONFIRMACION, MOVIMIENTO_RESET_CONFIRMACION,
    ACTUALIZA_IDSREPROCESAR } from '../types';

export default (state, action) => {
    switch( action.type ) {
        case MOSTRAR_LOGIN_ERRORES: 
            return {
                ...state,
                loginformErrors: action.payload.loginformErrors
            }
        case MOSTRAR_LOGIN_ERROR_AUTH:
            return {
                ...state,
                loginApiError: action.payload.error
            }
        case LOGIN_EXISTOSO:
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('expiracion', action.payload.expiracion);
            localStorage.setItem('email', action.payload.email);
            return {
                ...state,
                token: action.payload.token,
                email: action.payload.email,
                autenticado: true
            }
        case LOGOUT_EXISTOSO:
            localStorage.clear();
            return {
                ...state,
                token: null,
                autenticado: null
            }
        case MOVIMIENTO_CONFIRMACION:
            return {
                ...state,
                confirmado: true
            }
        case MOVIMIENTO_RESET_CONFIRMACION:
            return {
                ...state,
                confirmado: false
            }
        case ALERTAS_MSG:
            return {
                ...state,
                alerta: action.payload
            }
        case ACTUALIZA_IDSREPROCESAR:
            return {
                ...state,
                idReprocesar: action.payload
            }
        default:
            return state;
    }
}