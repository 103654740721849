import React, { useState, useEffect, useContext, useRef } from 'react';
import md5 from 'md5';
import Menu from '../../Menu';
import ModalConfirmacion from '../Confirmacion';
import AppContext from '../../../application/appContext';
const $ = window.$;

const FormReprocesar = ({ match, history }) => {
    const { confirmado, resetConfirmacion, mostrarAlerta, tokenValido, hacerlogout, idReprocesar } = useContext(AppContext);

    const [ hash, guardarHash ] = useState('');

    const [ infoMov, guardaInfoMov ] = useState( {
      encabezado: '',
      celdas: []
    } );

    useEffect(() => {

      if(idReprocesar.length <= 0) {
        history.push('/mov/rep/');
        return;
      }

      if( infoMov.celdas.length <= 0 ) { // Listamos el encabezado
        const hashMd5 = md5('rep' + idReprocesar.join());
        guardarHash(hashMd5);
        const request = {
          Movimiento: "rep",
          Hash: hashMd5,
	        Arr: idReprocesar.map( id => { return { DocId: id }; })
        }

        fetch(`${process.env.REACT_APP_API_URL}/Consultas/HeaderA`, {
          method: 'POST',
          body: JSON.stringify( request ),
          headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        })
        .then(
          (result) => result.json()
        )
        .then(
          (result) => {
            guardaInfoMov( result );
          }
        );
      }

      if(confirmado) {
        $("#confirmacion").modal('hide');
        // Validamos el token
        if( !tokenValido() ) {
          hacerlogout();
          return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/Movimientos/Reprocesar/${hash}`, {
          method: 'POST',
          body: {},
          headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        })
        .then(
          (result) => result.json()
        )
        .then(
          (result) => {

            if ( result.status >= 0) {
              mostrarAlerta( result.msg, 'success' );
              $('.toast').toast('show');
              resetConfirmacion();
              history.push(`/mov/rep/`);
              return;
            }
            
            mostrarAlerta( result.msg, 'error' );
            $('.toast').toast('show');
            resetConfirmacion();
          }
        );
      }

    }, [ confirmado, infoMov, hash ]);


    const onSubmit = (e) => {
      e.preventDefault();
      $("#confirmacion").modal('show');
    }

    return ( 
      <div className="container pt-5">
        <Menu />
        <div className="container-fluid mt-3">
          <div className="card">
            <div className="card-header text-center">
              <h4>{ infoMov.encabezado }</h4>
            </div>
            <div className="card-body pt-1 pb-1">
              <div className="row">
                {
                  infoMov.celdas.map( (celda) => <div className="col-12 col-md-4 mt-1" key={celda.etiqueta}> <b>{ celda.etiqueta }</b> { celda.valor }</div>)
                }
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            <form className="form justify-content-center" autoComplete="off"
                onSubmit={onSubmit}>
              <div className="row">
                <div className="col-12 col-md-4 offset-md-4 text-center">
                  <button type="submit" className="btn btn-primary mb-2">Procesar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ModalConfirmacion />
      </div>
    );
}
 
export default FormReprocesar;