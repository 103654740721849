export const MOSTRAR_LOGIN_ERRORES = 'MOSTRARLOGINERRORES';
export const MOSTRAR_LOGIN_ERROR_AUTH = 'MOSTRARLOGINERRORAUTH';
export const LOGIN_EXISTOSO = 'LOGIN_EXISTOSO';
export const LOGOUT_EXISTOSO = 'LOGOUT_EXISTOSO';

export const MOVIMIENTO_CONFIRMACION = 'MOVIMIENTO_CONFIRMACION';
export const MOVIMIENTO_RESET_CONFIRMACION = 'MOVIMIENTO_RESET_CONFIRMACION';


export const ALERTAS_MSG = 'ALERTAS_MSG';

export const ACTUALIZA_IDSREPROCESAR = 'ACTUALIZA_IDSREPROCESAR';