import React, { useEffect, useState, useContext, Fragment } from 'react';
import AppContext from '../../../application/appContext';
import Menu from '../../Menu';
import ModalConfirmacion from '../Confirmacion';
import { getContratos } from '../../ApiCalls';

const Reprocesar = ({ match, history }) => {

    const { tokenValido, hacerlogout, cambiaReprocesado } = useContext(AppContext);
    const [ resultados, cambiaResultados ] = useState({
        header: {},
        datos: []
      });
    const [ ids, cambiaIds ] = useState([]);
    const [ seleccionar, cambiaSeleccionados ] = useState(false);

    useEffect( () => {
      cambiaIds([]);
      cambiaSeleccionados(false);
      // Validamos el token
      if( !tokenValido() ) {
        hacerlogout();
        return;
      }

      if( resultados.datos.length === 0) {
        getContratos( 'rep', '' ).then(
          (listado) => {
            cambiaResultados( listado );
          }
        );
      }
    }, [ resultados ]);

    const onChange = (e) => {
      if( e.target.checked ){
        cambiaIds( [ ...ids, +e.target.value ]);
        if( (ids.length + 1) === resultados.length){
          cambiaSeleccionados(true);  
        }
      } else {
        if( ids.length === resultados.length){
          cambiaSeleccionados(false);  
        }
        cambiaIds( ids.filter( item => item !== +e.target.value ) );
      }
    }

    const onChangeTodos = (e) => {
      if( e.target.checked ){
        // agrega todos
        const todos = [];
        resultados.datos.map( r => todos.push(r.docId));
        cambiaIds( todos );
        cambiaSeleccionados(true);
      } else {
        cambiaIds([]);
        cambiaSeleccionados(false);
      }
    }

    const onSumbit = (e) => {
      e.preventDefault();
      if( ids.length <= 0 ) {
        return;
      }
      cambiaReprocesado(ids);
      history.push(`/mov/rep/info`);
    }

    return (
      <div className="containter-fluid pt-5">
        <Menu />
        <h2 className="mt-3 text-center">Reprocesar</h2>
        <form className="form-inline justify-content-center" autoComplete="off"
            onSubmit={onSumbit}>
          <button type="submit" className="btn btn-primary mb-2">Reprocesar</button>

          <div className="table-responsive">
            <table className="table table-hover table-sm">
                <thead>
                    <tr>
                        {
                          Object.keys(resultados.header).length > 0 ? 
                            [
                              <th scope="col" key="0" align="center" >
                                Todos
                                <div align="center">
                                <input  type="checkbox" onChange={onChangeTodos} checked={seleccionar}/>
                                </div>
                              </th>
                              ,
                              Object.keys(resultados.header).map( (key, index) => {
                                return resultados.header[key] ? <th scope="col" key={ index + 1 }>{ resultados.header[key] }</th>:null
                              })
                            ]
                            :
                            null
                        }
                    </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(resultados.header).length > 0 ? 
                      resultados.datos.length > 0 ?
                        resultados.datos.map( r => {
                          return (<tr key={r.docId} className="">
                            <td align="center" key="0"><input type="checkbox" value={r.docId} onChange={onChange} checked={ ids.some( item => item === r.docId)}/></td>
                            { 
                              Object.keys(resultados.header).map( (key, index) => { 
                                return resultados.header[key] ? <td key={index + 1}>{r[key]}</td>:null
                              })
                            }
                          </tr>)
                        })
                        :
                        null
                      :
                      null
                  }
                </tbody>
            </table>
          </div>
        </form>
        <ModalConfirmacion />
      </div>
    );
}
 
export default Reprocesar;