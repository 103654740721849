import React from 'react';
import Menu from './Menu';

const Home = () => {

    return (
        <div className="pt-5">
            <Menu />
            <div className="card text-center">
                <br></br>
                <br></br>
                <p>
                    <img src={"/calle.jpg"} alt={""} style={{width:512}} />
                </p>
            </div>
        </div>
    );
}
 
export default Home;
