import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../application/appContext';
import Menu from '../Menu';
import { getContratos } from '../ApiCalls';

const CuentasCobrar = ( { match, history } ) => {

    const { tokenValido, hacerlogout } = useContext(AppContext);
    const [ busqueda, cambiaFormBusqueda ] = useState('');
    const [ resultados, cambiaResultados ] = useState({
      header: {},
      datos: []
    });
    const [ encabezado, cambiaEncabezado ] = useState('');

    useEffect( () => {
      switch( match.params.metodo ){
        case 'cxc':
          cambiaEncabezado('Cuentas por cobrar');
          cambiaFormBusqueda('');
          cambiaResultados( {
            header: {},
            datos: []
          });
          break;
        case 'cxp':
          cambiaEncabezado('Cuentas por pagar');
          cambiaFormBusqueda('');
          cambiaResultados( {
            header: {},
            datos: []
          });
          break;
      }
    }, [match]);

    const onChange = (e) => {
      cambiaFormBusqueda( e.target.value );
    }

    const onSumbit = (e) => {
      e.preventDefault();
      if(busqueda.trim() === ''){
        return;
      }

      cambiaResultados( {
        header: {},
        datos: []
      } );
      // Validamos el token
      if( !tokenValido() ) {
        hacerlogout();
        return;
      }

      getContratos( match.params.metodo, busqueda ).then(
        (listado) => {
          cambiaResultados( listado );
        }
      );
    }

    const onClickRow = ( registro ) => {
      history.push(`/mov/${match.params.metodo}/${registro.docId}`);
    }

    return (  
      <div className="containter-fluid pt-5">
        <Menu />
        <h2 className="mt-3 text-center">{encabezado}</h2>
        <form className="form-inline justify-content-center" autoComplete="off"
            onSubmit={onSumbit}>
          <label className="mr-2" htmlFor="inlineFormInputName2"></label>
          <input type="text" className="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder=""
            onChange={onChange} 
            value={busqueda} />
          <button type="submit" className="btn btn-primary mb-2">Buscar</button>
        </form>

        <div className="table-responsive">
            <table className="table table-hover table-sm">
                <thead>
                    <tr>
                      {
                        Object.keys(resultados.header).length > 0 ? 
                          Object.keys(resultados.header).map( (key, index) => {
                            return resultados.header[key] ? <th scope="col" key={index}>{ resultados.header[key] }</th>:null
                          })
                          :
                          null
                      }
                    </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(resultados.header).length > 0 ? 
                      resultados.datos.length > 0 ?
                        resultados.datos.map( r => {
                          return (
                          <tr key={r.docId} className="pointer" onClick={() => onClickRow( r )}>
                            {
                              Object.keys(resultados.header).map( (key, index) => { 
                                return resultados.header[key] ? 
                                <td 
                                  key={index}>{r[key]}
                                </td>:null
                              })
                            }
                          </tr>)
                        })
                        :
                        null
                      :
                      null
                  }
                </tbody>
            </table>
        </div>
      </div>
    );
}

export default CuentasCobrar;