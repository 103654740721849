import React, { useState, useContext, useEffect } from 'react';
import AppContext from '../application/appContext';

const Login = (props) => {
  // Iniciamos el estado
  const [ formLogin, guardarFormLogin ] = useState({
    usuario: '',
    password: ''
  });

  const appContext = useContext(AppContext);
  const { loginformErrors, mostrarLoginErrores, loginApiError, hacerLogin, autenticado, token } = appContext;

  const { usuario, password } = formLogin;

  useEffect(() => {
    if(autenticado && token) {
      props.history.push('/home');
    }
  }, [ autenticado, token, props.history ]);

  const onChange = (e) => {
    guardarFormLogin({
      ...formLogin,
      [e.target.name]: e.target.value
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();

    loginformErrors.user.required = usuario.trim() === '' ? true:false;
    loginformErrors.password.required = password.trim() === '' ? true: false;
    mostrarLoginErrores( loginformErrors );

    if( !loginformErrors.user.required && !loginformErrors.password.required){
      hacerLogin( { Email: usuario, Password: password})
    }
  }

  return (
    <div className="container login-container">
      <div className="row">
        <div className="col-12 col-md-6 offset-md-3 login-form-1">
          <img src={"logoINM.png"} alt="logo" style={{width:300}} />
          <br></br>
          <br></br>
          <h4>Control de propiedades</h4>
          <form onSubmit={ onSubmit }>
              <div className="form-group">
                  <input type="text" 
                      className={ loginformErrors.user.required ? 'form-control is-invalid':'form-control' }
                      id="usuario" 
                      name="usuario" 
                      value={ usuario }
                      placeholder="Usuario" 
                      onChange={ onChange } />
                  <div className="invalid-feedback text-left">
                    El usuario es requerido.
                  </div>
              </div>
              <div className="form-group">
                  <input type="password" 
                      className={ loginformErrors.password.required ? 'form-control is-invalid':'form-control' }
                      id="password" 
                      name="password" 
                      value={ password }
                      placeholder="Contraseña" 
                      onChange={ onChange }/>
                  <div className="invalid-feedback text-left">
                    La contraseña es requerida.
                  </div>
              </div>
              { loginApiError !== '' ? <div className="alert alert-danger" role="alert">{ loginApiError }</div>: '' }
              <div className="form-group">
                  <input type="submit" className="btnSubmit" value="Ingresar"/>
              </div>
          </form>
        </div>
      </div>
    </div>
  );
}
 
export default Login;