import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppContext from '../application/appContext';
const $ = window.$;

const RutaPrivada = ( { component: Component, ...props }) => {

    const appContext = useContext( AppContext );
    const { autenticado, hacerlogout, tokenValido } = appContext;

    return (
      <Route {...props} render={ props => {
            $('.toast').toast('hide');
            if(!autenticado || !tokenValido()) {
              hacerlogout();
              return ( <Redirect to="/" /> );
            }
            return ( <Component {...props} /> );
          } 
        }/>
    )
}

export default RutaPrivada;


