export async function getContratos( mov ,busqueda ){
  
    var result = 
      fetch(`${process.env.REACT_APP_API_URL}/consultas/${mov}/${busqueda}`, {
        headers:{
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(
          (result) => {
            return result.json();
          }
      )
      .then(
        (listado) => listado);

  return result;
}
