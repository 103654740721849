import React, { useState, useEffect } from 'react';

const EncabezadoForm = ({ idMov, metodo }) => {
  // console.log(idMov, metodo);
  const [ infoMov, guardaInfoMov ] = useState( {
    encabezado: '',
    celdas: []
  } );

  useEffect( () => {
    const request = {
      DocId: +idMov,
      Movimiento: metodo
    };

    fetch(`${process.env.REACT_APP_API_URL}/consultas/header`, {
      method: 'POST',
      body: JSON.stringify( request ),
      headers:{
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    })
    .then(
      (result) => result.json()
    )
    .then(
      (result) => {
        guardaInfoMov( result );
      }
    );
  }, []);

  return (
    <div className="container-fluid mt-3">
      <div className="card">
        <div className="card-header text-center">
          <h4>{ infoMov.encabezado }</h4>
        </div>
        <div className="card-body pt-1 pb-1">
          <div className="row">
            {
              infoMov.celdas.map( (celda) => <div className="col-12 col-md-4 mt-1" key={celda.etiqueta}> <b>{ celda.etiqueta }</b> { celda.valor }</div>)
            }
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default EncabezadoForm;