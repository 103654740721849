import React, { useContext } from 'react';
import Menu from '../Menu';
import AppContext from '../../application/appContext';


const ModalConfirmacion = () => {
  const { confirmacion } = useContext(AppContext);

  const confirmar = () => {
    confirmacion();
  }

  return (<div className="modal" id="confirmacion" tabIndex="-1" role="dialog">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-body p-1">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <p className="text-center h6">¿Estás seguro?</p>
        </div>
        <div className="modal-footer justify-content-center p-1">
          <button type="button" className="btn btn-primary btn-sm" onClick={ confirmar }>Si</button>
          <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>)
}

export default ModalConfirmacion;