import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../application/appContext';

function GetUserId ()
{
    var t= localStorage.getItem('token');
    var seccion2= t.split('.')[1];
    var js= atob (seccion2); // decodifica y queda un string con formato Json asi:
        //{"unique_name":"p@p.com","UserId":"3","Nombre":"Pru","jti":"5aab6554-4e0f-4307-bd82-1a73f5e7ba2b","exp":1625782146}
        // ahora busca el elemento "UserId"
    const obj= JSON.parse (js);
    var UserId= obj.UserId;
    return UserId == null ? "0" : UserId;
}

const Menu = () => {
  // sección nueva, para los modulos de notas de credito y cancelaciones echas en BLAZOR
  const UserId= GetUserId ();
  const url_blazor= "https://inmob2-front-dev.dnsalias.com/";
  const url_nc= url_blazor + "Movimientos/NotaDeCredito/NotaDeCredito/" + UserId;
  const url_can= url_blazor + "Movimientos/CancelacionCFDi/CancelacionCFDi/" + UserId;
  const url_rep= url_blazor + "Movimientos/Reprocesar/Reprocesar/" + UserId;

  const appContext = useContext(AppContext);
  const { hacerlogout } = appContext;
  
  const url= "http://stockware01.dnsalias.com:26007/";
  const ServReportes= "ReportServer?%2fReportes+Inmobiliaria%2f";
  const Command= "&rs:Command=Render";

  // Sección de Reportes
  const ReporteAdeudos= url + ServReportes + "InmRepAdeudos" + Command;
  const ReporteAguaRenta= url + ServReportes + "InmRepAguayRenta" + Command;
  const ReporteDepositos= url + ServReportes + "InmRepDepositos" + Command;
  const ReporteFacPendientes=url + ServReportes + "InmRepFacPendientes" + Command;
  const ReportePreFacturacion=url + ServReportes + "InmRepPreFacturacion" + Command;
  const ReporteVencContrtos= url + ServReportes + "InmRepVencContratos" + Command;

  const ReporteGastos= url + ServReportes + "InmRepGastos" + Command;
  const ReportePagos= url + ServReportes + "InmRepPagos" + Command;
  const ReporteFacturacion=url + ServReportes + "InmRepFacturacion" + Command;
  const ReporteCxCMov=url + ServReportes + "InmRepCxCMov" + Command;

  // Sección de Videos
  const urlVideos= "https://stockware01.dnsalias.com/d/Videos/Inmobiliaria/"
  const extVideos= ".mp4";
  const VideoCancelaCxc= urlVideos + "iNMOBILIARIA-Cancela-CxC" + extVideos;
  const CatContratos= urlVideos + "iNMOBILIARIA-Cat-Contratos" + extVideos;
  const VideoCxc= urlVideos + "iNMOBILIARIA-CxC" + extVideos;
  const VideoCancelaPreFacturacion= urlVideos + "iNMOBILIARIA-Pre-Facturacion" + extVideos;
  const VideoServicios= urlVideos + "iNMOBILIARIA-Servicios" + extVideos;
  const VideoGastos= urlVideos + "iNMOBILIARIA-CxP" + extVideos;
  const VideoReprocesar= urlVideos + "iNMOBILIARIA-Reprocesar" + extVideos;
  const token= localStorage.getItem('token');
console.log ("url_nc=" + url_nc);

  return (
    <header>
      <nav className="navbar navbar-expand-md fixed-top bg-dark navbar-dark border-bottom shadow-sm">
      <a className="navbar-brand" href="#">

          <img src={"/logoINM.png"} alt={"Logo"} style={{width:80}} />
      </a>
      <Link className="navbar-brand" to="/home">Inmuebles</Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <Link className="nav-link text-light" to="/home">Inicio <span className="sr-only">(current)</span></Link>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Movimientos
              </a>
              <div className="dropdown-menu bg-dark" aria-labelledby="navbarDropdown">
                <Link className="nav-link text-light" to="/mov/refact">Prefacturación</Link>
                <Link className="nav-link text-light" to="/mov/srv">Captura de Servicios</Link>
                <Link className="nav-link text-light" to="/mov/cxc">Cuentas por Cobrar</Link>
                <Link className="nav-link text-light" to="/mov/gastos">Registro de Gastos</Link>
                <Link className="nav-link text-light" to="/mov/cxp">Cuentas por Pagar</Link>
                <a className="nav-link text-light" href= {url_rep} target="_blank">Reprocesar Facturas</a>
                <a className="nav-link text-light" href= {url_nc} target="_blank">Notas de Crédito</a>
                <a className="nav-link text-light" href= {url_can} target="_blank">Cancelaciones</a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdownReportes" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Reportes
              </a>
              <div className="dropdown-menu bg-dark" aria-labelledby="navbarDropdownReportes">
              <a className="nav-link text-light" href= {ReportePreFacturacion} target="_blank">Pre-Facturación</a>
                <a className="nav-link text-light" href= {ReporteAdeudos} target="_blank">Adeudos (CxC)</a>
                <a className="nav-link text-light" href= {ReporteAguaRenta} target="_blank">Servicios</a>
                <a className="nav-link text-light" href= {ReporteDepositos} target="_blank">Depósitos</a>
                <a className="nav-link text-light" href= {ReporteFacPendientes} target="_blank">Facturas Pendientes</a>
                <a className="nav-link text-light" href= {ReporteGastos} target="_blank">Gastos (CxP)</a>
                <a className="nav-link text-light" href= {ReportePagos} target="_blank">Pagos</a>
                <a className="nav-link text-light" href= {ReporteVencContrtos} target="_blank">Vencimientos</a>
                <a className="nav-link text-light" href= {ReporteFacturacion} target="_blank">Facturación</a>
                <a className="nav-link text-light" href= {ReporteCxCMov} target="_blank">Movimientos CxC</a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Videos
              </a>
              <div className="dropdown-menu bg-dark" aria-labelledby="navbarDropdown">
                <a className="nav-link text-light" href= {CatContratos} target="_blank">Catálogo de Contratos</a>
                <a className="nav-link text-light" href= {VideoCancelaPreFacturacion} target="_blank">Pre-Facturación</a>
                <a className="nav-link text-light" href= {VideoCxc} target="_blank">Cuentas por Cobrar</a>
                <a className="nav-link text-light" href= {VideoCancelaCxc} target="_blank">Cancelaciones</a>
                <a className="nav-link text-light" href= {VideoServicios} target="_blank">Servicios</a>
                <a className="nav-link text-light" href= {VideoGastos} target="_blank">Captura de Gastos</a>
                <a className="nav-link text-light" href= {VideoReprocesar} target="_blank">Reprocesar facturas</a>
              </div>
            </li>
          </ul>
          <form className="form-inline mt-2 mt-md-0">
            <button className="btn btn-success my-2 my-sm-0" type="button" onClick={ hacerlogout } >Salir!</button>
          </form>
        </div>
      </nav>
    </header>
  );
}
 
export default Menu;