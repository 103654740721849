import React, { useContext } from 'react';
import AppContext from '../application/appContext';

const Alertas = () => {
    const { alerta } = useContext( AppContext );
    
    return ( 
        <div aria-live="polite" aria-atomic="true" style={{ position: 'relative' }}>
            <div className="toast" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false"
                style={{ zIndex: 1050, position: 'absolute', top: 100, right: 30 }}>
            <div className="toast-header">
                <strong className="mr-auto">Mensaje</strong>
                <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="toast-body" dangerouslySetInnerHTML={{ __html: alerta.msg }}>
            </div>
            </div>
        </div>
    );
}
 
export default Alertas;