import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../../application/appContext';
import Menu from '../../Menu';
import ModalConfirmacion from '../Confirmacion';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
const $ = window.$;

registerLocale('es', es);

const FormPrefacturacion = () => {
  const { confirmado, resetConfirmacion, mostrarAlerta, tokenValido, hacerlogout } = useContext(AppContext);

  const today = new Date();
  const firstDayNextMonth = new Date(today.getFullYear(),today.getMonth() + 1, 1)
  const [startDate, setStartDate] = useState(firstDayNextMonth);
  const [ formMov, guardarForm ] = useState({
    fecha: format(firstDayNextMonth, 'dd/MM/yyyy'),
    observaciones: ''
  });

  const [ formError, stateErrorForm ] = useState( {
    fecha: {
      required: false,
    },
    observaciones: {
      required: false,
    }
  });

  useEffect(() => {
    if(confirmado) {
      $("#confirmacion").modal('hide');
      const request = {
        Fecha: formMov.fecha,
        Observaciones: formMov.observaciones,
      }
      // Validamos el token
      if( !tokenValido() ) {
        hacerlogout();
        return;
      }

      fetch(`${process.env.REACT_APP_API_URL}/Movimientos/PreFacturacion`, {
        method: 'POST',
        body: JSON.stringify( request ),
        headers:{
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(
        (result) => result.json()
      )
      .then(
        (result) => {

          if ( result.status >= 0) {
            mostrarAlerta( result.msg, 'success' );
            $('.toast').toast('show');
            setStartDate(new Date(today.getFullYear(),today.getMonth() + 1, 1));
            guardarForm({
              fecha: format(firstDayNextMonth, 'dd/MM/yyyy'),
              observaciones: ''
            });
          } else {
            mostrarAlerta( result.msg, 'error' );
            $('.toast').toast('show');
            
          }
          resetConfirmacion();
        }
      );
    }
  }, [ confirmado ]);

  const onChange = ( e ) => {
    guardarForm({
      ...formMov,
      [e.target.name]: e.target.value
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if ( !startDate ) {
      stateErrorForm({
        ...formError,
        fecha: {
          required: true,
        }
      });
      return;
    }

    if ( !formMov.observaciones ) {
      stateErrorForm({
        fecha: {
          required: false,
        },  
        observaciones: {
          required: true,
        }
      });
      return;
    }

    stateErrorForm({
      fecha: {
        required: false,
      },
      observaciones: {
        required: false,
      }
    });

    $("#confirmacion").modal('show');
  }

  return (
  <div className="container pt-5">
    <Menu />
    <div className="row">
      <div className="col-8 offset-2">
        <h4 className="mt-3">Prefacturación</h4>
        <form className="form justify-content-center" autoComplete="off"
            onSubmit={onSubmit}>
          <div className="row mb-2">
            <div className="col-12">
              <label className="mt-2" htmlFor="typehead">Fecha: </label>
              <div>
                <DatePicker 
                  className={ formError.fecha.required ? 'form-control is-invalid':'form-control' }
                  dateFormat="dd/MM/yyyy"
                  locale="es"
                  minDate={today} 
                  selected={startDate} 
                  name="fecha"
                  onChange={ date => { 
                    guardarForm({
                      ...formMov,
                      fecha: format(date, 'dd/MM/yyyy')
                    });
                    setStartDate(date)
                  }} />
              </div>
            </div>
            <div className="col-12">
              <label className="mt-2" htmlFor="inlineFormInputName2">Observaciones: </label>
              <textarea 
                className={ formError.observaciones.required ? 'form-control is-invalid':'form-control' }
                name="observaciones" 
                rows="3" 
                onChange={onChange} 
                value={formMov.observaciones}></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <button type="submit" className="btn btn-primary mb-2">Procesar</button>
            </div>
          </div>
        </form>
      </div>
      </div>
    <ModalConfirmacion />
  </div>
  );
}
 
export default FormPrefacturacion;