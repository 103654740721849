import React, { useReducer } from 'react';

import AppContext from './appContext';
import appReducer from './appReducer';

import { ALERTAS_MSG,
    MOSTRAR_LOGIN_ERRORES, MOSTRAR_LOGIN_ERROR_AUTH,
    LOGIN_EXISTOSO, LOGOUT_EXISTOSO,
    MOVIMIENTO_CONFIRMACION, MOVIMIENTO_RESET_CONFIRMACION,
    ACTUALIZA_IDSREPROCESAR } from '../types';

const AppState = props => {
    const tokenValido = () => {
        const fechaActual = new Date();
        if(!localStorage.getItem('token') || !localStorage.getItem('expiracion')) {
            return false;
        }

        const fechaExpiracion = new Date(localStorage.getItem('expiracion'));
        if( fechaExpiracion.getTime() <= fechaActual.getTime()){
            return false;
        }

        return true;
    }

    const initalState = {
        nuevo: false,
        loginformErrors: {
            user: {
                required: false
            },
            password: {
                required: false
            },
        },
        loginApiError: '',
        email: localStorage.getItem('email'),
        token: localStorage.getItem('token'),
        autenticado: tokenValido(),
        usuario: null,
        confirmado: false,
        alerta: {
            msg: '',
            tipo: ''
        },
        idReprocesar: []
    }

    // Dispatch
    const [ state, dispatch] = useReducer( appReducer, initalState);
    const { email } = state;

    const mostrarLoginErrores = ( loginformErrors ) => {
        dispatch({
            type: MOSTRAR_LOGIN_ERRORES,
            payload: {
                loginformErrors
            }
        })
    }

    const hacerLogin = async request => {
        const url = process.env.REACT_APP_API_URL + '/cuentas/login';
        
        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers:{
                'Content-Type': 'application/json'
            }
        });

        if( result.ok ){
            const data = await result.json();
            dispatch({
                type: LOGIN_EXISTOSO,
                payload: {
                    token: data.token,
                    expiracion: data.expiration,
                    email: request.Email
                }
            });
        } else {
            const mgsError = await result.text();
            dispatch({
                type: MOSTRAR_LOGIN_ERROR_AUTH,
                payload: {
                    error: mgsError
                }
            });
        }
    }

    const hacerlogout = async () => {
        const url = process.env.REACT_APP_API_URL + '/cuentas/logout';
        
        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify( { Email: email }),
            headers:{
                'Content-Type': 'application/json'
            }
        });

        if( result.ok ){
            dispatch({
                type: LOGOUT_EXISTOSO,
                payload: {}
            });
        } else {
            const mgsError = await result.text();
        }
    }

    const confirmacion = () => {
        dispatch({
            type: MOVIMIENTO_CONFIRMACION
        });
    }

    const resetConfirmacion = () => {
        dispatch({
            type: MOVIMIENTO_RESET_CONFIRMACION
        });
    }

    const mostrarAlerta = (msg, tipo) => {
        dispatch({
            type: ALERTAS_MSG,
            payload: { msg, tipo }
        })
    }

    const cambiaReprocesado = ( ids ) => {
        dispatch({
            type: ACTUALIZA_IDSREPROCESAR,
            payload: ids
        })
    }

    return (
        <AppContext.Provider
            value={{
                ...state,
                mostrarLoginErrores,
                hacerLogin,
                hacerlogout,
                confirmacion,
                resetConfirmacion,
                mostrarAlerta,
                tokenValido,
                cambiaReprocesado
            }}
        >
            { props.children }
        </AppContext.Provider>
    )
}

export default AppState;