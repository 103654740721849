import React, { useState, useEffect, useContext } from 'react';
import Menu from '../Menu';
import ModalConfirmacion from './Confirmacion';
import AppContext from '../../application/appContext';
import EncabezadoForm from './Encabezado';
const $ = window.$;

const Movimiento = ({ match, history }) => {
    const { confirmado, resetConfirmacion, mostrarAlerta, tokenValido, hacerlogout } = useContext(AppContext);

    const [ formMov, guardarForm ] = useState({
      importe: '',
      referencia: '',
      observaciones: ''
    });

    const [ formError, stateErrorForm ] = useState( { 
      required: false,
      min: false,
    });

    useEffect(() => {
      if(confirmado) {

        $("#confirmacion").modal('hide');
        const request = {
          DocId: +match.params.idMov,
	        Importe: +formMov.importe,
          Referencia: formMov.referencia,
          Observaciones: formMov.observaciones 
        }
        // Validamos el token
        if( !tokenValido() ) {
          hacerlogout();
          return;
        }
        var servicio= "";
        switch (match.params.metodo) {
          case "cxp":
            servicio= "Movimientos/CuentasPorPagar";
            break;
          case "cxc":
            servicio= "Movimientos/CuentasPorCobrar";
            break;
          default:
            servicio= match.params.metodo;
            break;
          }
        //console.log ("ddt [" + servicio +"]");
        fetch(`${process.env.REACT_APP_API_URL}/${servicio}`, {
          method: 'POST',
          body: JSON.stringify( request ),
          headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        })
        .then(
          (result) => result.json()
        )
        .then(
          (result) => {

            if ( result.status >= 0) {
              mostrarAlerta( result.msg, 'success' );
              $('.toast').toast('show');
              resetConfirmacion();
              history.push(`/mov/${match.params.metodo}/`);
            }
            
            mostrarAlerta( result.msg, 'error' );
            $('.toast').toast('show');
            resetConfirmacion();
            
          }
        );
      }
    }, [ confirmado ]);

    const onChange = ( e ) => {
      guardarForm({
        ...formMov,
        [e.target.name]: e.target.value
      });
    }

    const onSubmit = (e) => {
      e.preventDefault();
      if( formMov.importe.trim() === '' ) {
        stateErrorForm({
          required: true,
          min: false
        });
        return;
      }

      if( formMov.importe.trim() <= 0 ) {
        stateErrorForm({
          required: false,
          min: true
        });
        return;
      }

      stateErrorForm({
        required: false,
        min: false
      });

      $("#confirmacion").modal('show');
    }

    return ( 
      <div className="container pt-5">
        <Menu />
        <EncabezadoForm idMov={match.params.idMov} metodo={match.params.metodo}/>
        <div className="row">
          <div className="col-8 offset-2">
            <form className="form justify-content-center" autoComplete="off"
                onSubmit={onSubmit} noValidate>
              <label className="mt-2" htmlFor="inlineFormInputName2">Importe: </label>
              <input type="number" id="inlineFormInputName2" placeholder=""
                className={ formError.required || formError.min ? 'form-control is-invalid':'form-control' }
                min="0"
                name="importe"
                onChange={onChange} 
              />
              { 
                formError.required ? 
                  (<div className="invalid-feedback text-left">
                    El importe es requerido.
                  </div>) : null
              }
              {
                formError.min ? 
                  (<div className="invalid-feedback text-left">
                    El importe debe ser mayor a 0.
                  </div>) : null
              }
              <label className="mt-2" htmlFor="inlineFormInputName2">Referencia: </label>
              <input type="text" className="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder=""
                name="referencia"
                onChange={onChange} 
              />
              <label className="mt-2" htmlFor="inlineFormInputName2">Observaciones: </label>
              <textarea className="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder=""
                name="observaciones"
                onChange={onChange}
              ></textarea>
              <button type="submit" className="btn btn-primary mb-2">Aplicar</button>
            </form>
          </div>
        </div>
        <ModalConfirmacion />
      </div>
    );
}
 
export default Movimiento;